import './App.css';
import SaintHubert from './images/Logo-st-Hubert.png'
import Algego from './images/logo-blanc.png'
import Barreau from './images/Barreau.png'
import Bixi from './images/BIXI.png'
import Bonduelle from './images/Bonduelle.png'
import CQF from './images/CQF.png'
import Desjardins from './images/Desjardins.png'
import Hema from './images/Hema.png'
import Loto from './images/Loto.png'

function Lea() {
  return (
    <>
      <div className='body'>
        <div className='allContent'>
        <a href='https://algego.com' target='_blank'><div className='algegoContainer'><img className='algegoLogo' src={Algego} alt='ALGEGO' /></div></a>
          <div className='headerDiv'>
            <div className='mainTitle'>La relève des Jedi</div>
            <div className='mainSubTitle'>Cohorte LEA</div>
            <div className='subTitle'>
              <p>Création du plan de relève pour les gestionnaires</p>
              {/* <p>pour les gestionnaires de la bannière St-Hubert.</p> */}
            </div>
            <img className='saintHuberLogo' src={SaintHubert} alt='Saint Hubert' />
          </div>
          
          <div className='textSection'>
            <div className='insideTextSection'>
              <div className='pargraphTitle'>Votre projet</div>

              <p>Le projet consiste à créer un plan de relève destiné à un groupe de 15 gestionnaires (administration, opérations, marketing, etc.) travaillant au sein de la bannière St-Hubert.</p>

              <p>Ce plan d’une durée de 6 à 8 mois sera rythmé par la tenue d’ateliers d'une demi-journée en présentiel, et ce, à raison d’un atelier aux 6 semaines.</p>

              <p>L'objectif est de développer l'autodétermination, l'autonomie et la capacité à transmettre les connaissances de vos gestionnaires aux autres employés.</p>

              <p>Le programme doit s’éloigner des schémas traditionnels et s'inspirer des valeurs de la saga de <em>La Guerre des étoiles</em>. La cohorte est baptisée LEA (pour Leadership en action), nom qui a inspiré le parallèle avec la Princesse Leïa qui incarne bien ces valeurs.</p>

              <div className='pargraphTitle'>Notre intention</div>

              <p>Nous proposons de créer un parcours « initiatique » qui permettra aux 15 gestionnaires d’acquérir la « force » (les compétences et valeurs nécessaires au développement de la bannière St-Hubert) afin de devenir de vrais… Jedi.</p>

              <p>Le programme s’inspire des enseignements des Jedi de <em>La Guerre des étoiles</em>. Ainsi, en considérant une période de 8 mois avec un atelier aux 6 semaines, il se divise en 5 ateliers animés en présentiel et optimisés avec les nouvelles technologies virtuelles (réalité augmentée, hologrammes et visioconférences interactives) visant à renforcer et développer :</p>

              <ul>
                <li>la confiance en soi</li>
                <li>le leadership</li>
                <li>les compétences de gestion</li>
                <li>la culture d'entreprise</li>
                <li>et le réseau professionnel St-Hubert de chaque participant</li>
              </ul>

              <p>Les enseignements des Jedi, tels que la force intérieure, la résilience, l'empathie et la responsabilité, seront intégrés dans le contenu des ateliers, et ce, pour aider les participants à développer leur potentiel et leur permettre ainsi d'atteindre leurs objectifs personnels et professionnels.</p>

              <p>Afin de rester proche de l’univers de <em>La Guerre des étoiles</em> et favoriser l’acquisition de compétences dans le plaisir, nous proposons d’intégrer les nouvelles technologies de la réalité augmentée et de la réalité virtuelle pour créer des expériences de groupe pour les membres de la cohorte LEA 2023-2024.</p>

              <div className='pargraphTitle'>Suggestion de parcours initiatique en 5 ateliers</div>

              <p><em>À noter également que le contenu des ateliers sera créé et animé par différents experts internes (issus des ressources internes de St Hubert) ou externes.
              </em></p>

              <div className='sectionTitle'>1. Formation Jedi | La confiance en soi</div>

              <div className='numericSection'>
                <div className='numericSectionTxt'>
                  <p>L’atelier Jedi sera animé par un expert en développement personnel et se concentrera sur l'art de la maîtrise de soi, la véritable source de notre force intérieure. À travers des exercices pratiques, l'animateur dispensera des enseignements pour faire face aux défis les plus complexes, notamment la résolution de problèmes et la gestion du stress.</p>
                  <p>Dans le cadre de cet atelier, les participants auront la possibilité de découvrir des clés pour s’ouvrir à la pratique de techniques de méditation et de développement personnel éprouvées, afin de renforcer leur confiance en soi et améliorer leur bien-être psychologique.</p>
                </div>
              </div>

              <div className='sectionTitle'>2. Défi de la Force | Le leadership à l'épreuve</div>

              <div className='numericSection'>
                <div className='numericSectionTxt'>
                  <p>Ce deuxième atelier est axé sur la relève de défis de leadership et de gestion de franchise en équipe. Ce sera l’occasion pour les participants de développer leurs compétences de leadership tout en faisant face à des défis opérationnels complexes.</p>
                  <p>Grâce à la simulation de la gestion d'un restaurant St-Hubert fictif, à la résolution de problèmes opérationnels exigeants et à la création de solutions innovantes, les participants seront mis au défi de démontrer leur capacité à travailler en équipe et à prendre des décisions éclairées dans un environnement dynamique et stimulant.</p>
                  <p>Cet atelier sera enrichi par la <strong>réalité augmentée</strong>, technologie qui permet de faire apparaître dans la salle des objets virtuels et de concevoir des jeux captivants pour créer des mises en situation réalistes.</p>
                  <p>Ainsi « immergés » dans un contexte proche de leur réalité, les participants pourront mettre en pratique leurs compétences de leadership et de gestion, et ce, en temps réel.</p>
                </div>
              </div>

              <div className='sectionTitle'>3. Mentorat Jedi | Expérience et compétence</div>

              <div className='numericSection'>
                <div className='numericSectionTxt'>
                  <p>L'atelier de mentorat et de partage d'expériences est une opportunité incontournable pour les participants souhaitant développer leur leadership et renforcer leurs compétences professionnelles.</p>
                  <p>L'objectif de cet atelier est de permettre aux apprenants de bénéficier de l'expertise de mentors chevronnés, issus des cohortes précédentes ou de la haute direction de la bannière.</p>
                  <p>Ce transfert d’expérience et de connaissances se fera grâce à la projection d’<strong>hologrammes</strong> qui permettrait de faire apparaître les avatars des mentors en trois dimensions, et ce, au beau milieu de la salle… comme dans <em>La Guerre des étoiles</em>. Par leurs interventions, ces derniers guideront les participants dans l'identification de leurs forces et faiblesses, dans l'élaboration de plans de développement professionnel personnalisés, et dans la gestion de commentaires constructifs.</p>
                  <p>Lors de cet atelier interactif et ludique, rythmé par des jeux de questions-réponses, les participants auront la possibilité de renforcer leur réflexion, leur confiance en eux et d'améliorer leur performance.</p>
                </div>
              </div>

              <div className='sectionTitle'>4. Formation sur les valeurs Jedi | Culture d’entreprise</div>

              <div className='numericSection'>
                <div className='numericSectionTxt'>
                  <p>L'atelier de formation sur les valeurs Jedi est un événement clé pour les gestionnaires souhaitant développer leur culture d'entreprise. Cet atelier, animé par un expert, sera axé sur les valeurs fondamentales de l'univers Jedi, telles que la compassion, la résilience et la responsabilité, et sur la manière dont ces valeurs peuvent être appliquées à la gestion d'une franchise St-Hubert.</p>
                  <p>Les participants auront l'opportunité de découvrir comment elles peuvent contribuer à créer une culture d'entreprise positive, à améliorer les performances des employés, et à renforcer la satisfaction des clients.</p>
                  <p>Les gestionnaires seront également encouragés à trouver des moyens novateurs pour transmettre ces mêmes valeurs à leurs employés afin de créer un environnement de travail inspirant et stimulant.</p>
                </div>
              </div>

              <div className='sectionTitle'>5. Échanges intergalactiques | Ensemble</div>

              <div className='numericSection'>
                <div className='numericSectionTxt'>
                  <p>Ce dernier rendez-vous est le point culminant de notre plan de relève, offrant une occasion privilégiée pour les participants d'échanger avec les gestionnaires issus de la galaxie St-Hubert.</p>
                  <p>Cet atelier final est une opportunité qui permettra de faire une synthèse complète du parcours initiatique, de partager des idées novatrices et des meilleures pratiques, et ce, dans un esprit de collaboration et de coopération mutuelle.</p>
                  <p>Les échanges seront organisés dans un format mixte dans la salle de l’atelier, avec la présence physique des gestionnaires, tandis que les représentants des autres galaxies (les restaurants St-Hubert) pourront se connecter en mode visioconférence (ex.: Zoom, Teams).</p>
                  <p>Ces échanges seront animés avec l’énergie et l’esprit visionnaire de <em>La Guerre des étoiles</em>, question de stimuler l'innovation et la créativité de tous les participants.</p>
                  <p>Grâce à cette mise en relation, les participants pourront découvrir de nouvelles idées, confirmer leurs nouveaux acquis, échanger sur des pratiques éprouvées, et enfin, élargir leur réseau relationnel interne dans la grande communauté que forment les gestionnaires de St-Hubert.</p>
                </div>
              </div>
            </div>
            <div className='callToAction'>Pour plus d’information : <div className='forHover'><strong><a href='mailto:julieguillaume@algego.com'>CONTACTER NOTRE FORCE</a></strong></div>
            </div>
          </div>
          <div className='emptyDivDown'></div>

          {/* <div><img className='algegoLogo' src={Algego} alt='ALGEGO' /></div> */}
          {/* <a href='mailto:julieguillaume@algego.com'><button>CONTACTER NOTRE FORCE</button></a> */}
        </div>
      </div>
      <div className='logoWrapper'>
        <div className='logoImageWrapper'>
          <div className='oneLogo'><img src={Barreau} alt='Barreau'></img></div>
          <div className='oneLogo'><img src={Bixi} alt='BIXI'></img></div>
          <div className='oneLogo'><img src={Bonduelle} alt='Bonduelle'></img></div>
          <div className='oneLogo'><img src={CQF} alt='CQF'></img></div>
          <div className='oneLogo'><img src={Desjardins} alt='Desjardins'></img></div>
          <div className='oneLogo'><img src={Hema} alt='Héma Québec'></img></div>
          <div className='oneLogo'><img src={Loto} alt='Loto Québec'></img></div>
        </div>
      </div>
    </>
  );
}

export default Lea;
